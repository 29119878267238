// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-src-templates-post-js": () => import("/opt/build/repo/node_modules/gatsby-theme-blog/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-blog-src-templates-posts-js": () => import("/opt/build/repo/node_modules/gatsby-theme-blog/src/templates/posts.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-posts-js" */),
  "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js": () => import("/opt/build/repo/node_modules/gatsby-theme-mdx-deck/src/templates/deck.js" /* webpackChunkName: "component---node-modules-gatsby-theme-mdx-deck-src-templates-deck-js" */),
  "component---theme-src-templates-home-js": () => import("/opt/build/repo/theme/src/templates/home.js" /* webpackChunkName: "component---theme-src-templates-home-js" */)
}

